<template>
    <!-- ============================ Page Title Start================================== -->
    <div class="page-title  bl-dark" >
        <div class="container">

        </div>
    </div>
    <!-- ============================ Page Title End ================================== -->

    <!-- ============================ Main Section Start ================================== -->
    <section class="gray-bg pt-4">
        <div class="container-fluid">
            <div class="row m-0">

                <account-sidebar :activeMenu="settingsType" activeBlock="settings" >
                </account-sidebar>

                <!-- Item Wrap Start -->
                <div class="col-xl-9 col-lg-8 col-md-12 col-sm-12">
                    <div class="row" >
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <!-- Breadcrumbs -->
                            <div class="bredcrumb_wrap">
                                <nav aria-label="breadcrumb">
                                  <ol class="breadcrumb">
                                    <li class="breadcrumb-item"><a href="#">Account Settings</a></li>
                                    <li class="breadcrumb-item active" aria-current="page">Edit password / Email</li>
                                  </ol>
                                </nav>
                            </div>
                        </div>
                    </div>

                    <div class="row" v-if="settingsType === 'password' ">
                        <div class="col-lg-12 col-md-12">
                            <div class="dashboard-gravity-list with-icons">
                                <h4>Change Password</h4>
                                <!-- form -->
                                <create-auth-change-password>
                                </create-auth-change-password>
                                <!--/ form -->
                            </div>
                        </div>

                    </div>


                    <div class="row" v-if="settingsType === 'email' " >
                        <div class="col-lg-12 col-md-12">
                            <div class="dashboard-gravity-list with-icons">
                                <h4>Change email</h4>
                                <!-- form -->
                                <create-auth-change-email>
                                </create-auth-change-email>
                                <!--/ form -->
                            </div>
                        </div>

                    </div>


                    <div class="row" v-if="settingsType === 'preferences' " >
                        <div class="col-lg-12 col-md-12 col-12">
                            <div class="dashboard-gravity-list with-icons">
                                <h4>Preferences</h4>
                                <ul>
                                    <li>
                                        <i class="dash-icon-box ti-layers text-purple bg-light-purple"></i> Your job for <strong><a href="#">IOS Developer</a></strong> has been approved!
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-star text-success bg-light-success"></i> Jodie Farrell left a review <div class="numerical-rating high" data-rating="5.0"></div> for<strong><a href="#"> Real Estate Logo</a></strong>
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-heart text-warning bg-light-warning"></i> Someone bookmarked your <strong><a href="#">SEO Expert Job</a></strong> listing!
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-star text-info bg-light-info"></i> Gracie Mahmood left a review <div class="numerical-rating mid" data-rating="3.8"></div> on <strong><a href="#">Product Design</a></strong>
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-heart text-danger bg-light-danger"></i> Your Magento Developer job expire<strong><a href="#">Renew</a></strong> now it!
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-star text-success bg-light-success"></i> Ethan Barrett left a review <div class="numerical-rating high" data-rating="4.7"></div> on <strong><a href="#">New Logo</a></strong>
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>

                                    <li>
                                        <i class="dash-icon-box ti-star text-purple bg-light-purple"></i> Your Magento Developer job expire <strong><a href="#">Renew</a></strong> now it.
                                        <a href="#" class="close-list-item"><i class="fa fa-close"></i></a>
                                    </li>
                                </ul>
                            </div>
                        </div>


                    </div>


                </div>

            </div>
        </div>
    </section>
    <!-- ============================ Main Section End ================================== -->
</template>

<script>
// @ is an alias to /src
import { useStore } from 'vuex';
import AccountSidebar from '@/components/account/AccountSidebar.vue';
import CreateAuthChangeEmail from '@/components/forms/auth/CreateAuthChangeEmail.vue';
import CreateAuthChangePassword from '@/components/forms/auth/CreateAuthChangePassword.vue';

export default {
  name: "AccountDashboard",
  props: {
      settingsType:{
          type:String,
          required:true,
          default:"password"
      }
  },
  components:{
    AccountSidebar,
    CreateAuthChangeEmail,
    CreateAuthChangePassword
  },
  setup(){

      // get the global vuex store values
      const store = useStore();

      // use the dispatch function to call an action that calls a mutation
      store.dispatch("globals/executeSetNavMenu",true);
      store.dispatch("globals/executeSetFooterMenu",true);



  }


};
</script>

<style scoped>

.employer-brands ul li{
    list-style-type:none;
    display:inline;
}

.icon-size{
    font-size:2rem;
}

</style>
