<template>
 <div class="_dashboard_content" v-if="!isResetSuccessful">
  <form  @submit.prevent="createChangeEmail" >

        <div class="_dashboard_content_body">
            <div class="row">
                <div class="col-xl-6 col-lg-6 col-md-6 col-12">

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.email.msg?'text-danger':''" for="email">
                                Email
                                <span v-if="fv.email.msg" > | {{fv.email.msg}}</span>
                            </label>
                            <input type="text" placeholder="Enter email"
                                   required v-model.trim="fv.email.val"
                                   @keyup="validate.validateFormData(fv,'email',true)"
                                   class="form-control with-light" id="email">
                        </div>
                    </div>

                    <div class="col-xl-12 col-lg-12 col-12">
                        <div class="form-group">
                            <label :class="fv.password.msg?'text-danger':''" for="password">
                                Current Password
                                <span v-if="fv.password.msg" > | {{fv.password.msg}}</span>
                            </label>
                            <div class="input-group">
                                <input class="form-control with-light" required id="password"
                                       :type="fv.password.showPassword?'text':'password'" v-model="fv.password.val"
                                       :class="fv.password.msg?'border-danger':''"
                                       @keyup="validate.validateFormData(fv,'password',true)"
                                       placeholder="Enter password">
                                <div class="input-group-prepend rounded-right  cursor-pointer">
                                    <span class="input-group-text b-0 rounded-right"
                                          id="basic-addon1"
                                          @click="togglePasswordVisibility">
                                        <i class="bi " :class="fv.password.showPassword?'bi-eye-slash-fill':'bi-eye-fill'"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div class="col-12" v-if="fv.reqFeedback.status">

                        <base-list-tag class="mt-1" :tagColor="fv.reqFeedback.val" >{{fv.reqFeedback.msg}}</base-list-tag>
                    </div>


                    <div class="col-xl-12 col-lg-12 col-12" v-if="fv.showSubmit.val">
                        <button type="submit" class="btn btn-save col-12">Change Email</button>
                    </div>
                    <base-spinner class="ml-3" v-else></base-spinner>

                </div>
            </div>

        </div>





  </form>
 </div>


<base-card-response  cardColor="text-white" class="mb-4" cardType="success" v-if="isResetSuccessful">

         <template #header>Email change was successful. </template>
         <template #default>
             <p>
                 You have successfully changed your password.
             </p>

         </template>

</base-card-response>


</template>


<script>
import { ref,reactive,inject } from 'vue'
import validators from '@/hooks/validation'

export default{

  name: 'CreateAuthChangeEmail',
  props: {
      resetId:{
          type:String,
          default:"nothing"
      }
  },
  setup() {

    // get the validators
    const validate = validators();

    // get the axios object
    const axios = inject('axios');  // inject axios

    //get the urls
    const coreUrl = inject('coreUrl');


    const reqError  = ref("Loading login form....");




     // create variables for the values to be submitted
    const fv = reactive({
        email : {status:false, val:"", msg:false, active:true, validate:["required","email"]},
        password : {status:false, val:"", msg:false, showPassword: false, validate:["required",
                                                                                      {option:"textMin",val:10},
                                                                                      {option:"textMax",val:64}
                                                                                     ]},
        reqFeedback : {status:false, val:"", msg:false},
        showSubmit : {status:false, val:true}
    })


    // toggle password visibility
    function togglePasswordVisibility(){
        fv.password.showPassword = !fv.password.showPassword;
    }

    // get the current status of the created account
    const isResetSuccessful = ref(false);

    // create the account using async
    async function createChangeEmail(){

        fv.showSubmit.val = false;

        // load the form data , and append all the necessary fields
        let formData = new FormData();

        formData.append("fv_email", fv.email.val);
        formData.append("fv_password", fv.password.val);

        // post the data using axios
        try{

            const response = await axios.put(
                coreUrl.backendUrl+'create_email_change',
                formData,
                {
                    withCredentials: true,
                    headers:{
                       "Content-Type": "multipart/form-data"
                    }
                }
            );

            // If successful, get the resppnse data
            const responseData = response.data;

            if(responseData.status){
                // update is account created status
                isResetSuccessful.value = true;
                fv.reqFeedback.status = false;

                fv.showSubmit.val = true;
            }else{

                for(const key in responseData.message){
                    if(fv[key]){
                        fv[key]["status"] = false;
                        fv[key]["msg"] = responseData.message[key];
                    }
                }


                fv.reqFeedback.status = true;
                fv.reqFeedback.msg = responseData.message['req_msg'];
                fv.reqFeedback.val = "danger";
                fv.showSubmit.status = false;
                fv.showSubmit.val = true;
            }




        }catch(error){


            // load the error to the appropriate fiel

            fv.reqFeedback.status = true;
            fv.reqFeedback.msg = "We had issues processing your request";
            fv.reqFeedback.val = "danger";
            fv.showSubmit.status = false;
            fv.showSubmit.val = true;

            // end load the error to the appropriate field

        }

     }



    return {
        fv,
        validate,
        reqError,
        togglePasswordVisibility,
        createChangeEmail,
        isResetSuccessful
     }
  }
}
</script>
